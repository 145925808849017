<template>
	<div style="height: 100%; overflow: auto" >
		<div class="user-card md-elevation-4">
			<div class="user-card-content" style="padding: 10px 15px">
				<div class="md-title" >
					<md-avatar class="md-avatar-icon" >
						<md-icon>attach_money</md-icon>
					</md-avatar>
					<span style="margin-left: 10px">{{lang.cargos_totales2}}</span>
				</div>
				<div class="md-subhead charge" style="padding: 15px 10px">
					<template v-for="cargo in cargos_totales">
						<div class="charge-totals" :class="cargo.css">
							<div class="charge-label" >
								<md-icon>{{cargo.icon}}</md-icon> {{cargo.label}}
							</div>
							<div class="charge-price">
								{{currency}} {{cargo.valor}}
							</div>
						</div>
						<hr v-if="cargo.hr">
					</template>
				</div>

			</div>
		</div>

		<div class="user-card md-elevation-4">
			<div class="user-card-content" style="padding: 10px 15px">
				<div class="md-title" >
					<md-avatar class="md-avatar-icon" >
						<md-icon>king_bed</md-icon>
					</md-avatar>
					<span style="margin-left: 10px">{{lang.cargos_del_alojamiento}}</span>
				</div>
				<div class="md-subhead charge" style="padding: 15px 10px">
					<div class="charge-totals" v-for="cargo in cargos_alojamiento" >
						<div class="charge-label">
							{{cargo.label}}
						</div>
						<div class="charge-price">
							{{currency}}  {{cargo.valor}}
						</div>
					</div>
				</div>

			</div>
		</div>

		<div class="user-card md-elevation-4">
			<div class="user-card-content" style="padding: 10px 15px">
				<div class="md-title" >
					<md-avatar class="md-avatar-icon" >
						<md-icon>nights_stay</md-icon>
					</md-avatar>
					<span style="margin-left: 10px">{{lang.tarifas_por_noche}}</span>
				</div>
				<div class="md-subhead charge" style="padding: 15px 10px">
					<div class="charge-totals" v-for="tarifa in tarifas_noches" >
						<div class="charge-label">
							{{tarifa.dia}}
						</div>
						<div class="charge-price">
							{{currency}}  {{parseFloat(tarifa.precio)}}
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>
<script>

	export default {
		name: 'tab-cargos',
		computed:{
			lang(){return this.$store.state.lang},
			cargos_alojamiento(){
				let booking = this.$store.state.booking;
				// tarifaTotal -sumaTotalPagar - hospedajetotal
				let total_alojamiento = 0;
				let descuento = 0;
				let sin_impuestos = 0;
				let impuestos = 0;

				if(booking.detallesBasicos){
					total_alojamiento  = parseFloat(booking.tarifaTotal);
					descuento = parseFloat(booking.ajustesAlojamiento[0].totalAjusteAlojamiento);
					sin_impuestos = parseFloat(booking.detallesBasicos[0].total_alojamiento);
					impuestos = parseFloat(booking.detallesBasicos[0].impuesto);
				}

				let label = this.lang.tarifa_para+' '+booking.cantidad_noches+''+this.lang.noches

				return [
					{label: this.lang.tarifa, valor: total_alojamiento},
					{label: this.lang.descuentos, valor: descuento},
					{label: this.lang.subtotal, valor: sin_impuestos},
					{label: this.lang.impuestos, valor: impuestos},
				]
			},

			tarifas_noches(){return this.$store.state.booking.tarifa_x_dia},
			cargos_totales(){
				let booking  = this.$store.state.booking;
				let total_alojamiento = 0;
				let consumos_y_extras = 0;
				let total_mayuscula = 0;
				let pagado = 0;
				let pendiente = 0;
				if(booking.detallesBasicos){
					total_alojamiento  = parseFloat(booking.detallesBasicos[0].total_alojamiento) + parseFloat(booking.detallesBasicos[0].impuesto);
					consumos_y_extras = parseFloat(booking.totalConsumos[0].totalConsumos) + parseFloat(booking.totalImpuestosConsumo[0].totalImpuestosConsumo);
					total_mayuscula = parseFloat(booking.sumaTotalPagar);
					pagado = parseFloat(booking.ingresos[0].totalIngreso);
					pendiente = total_mayuscula - pagado;

				}
				return[
					{icon: 'nights_stay', label:  this.lang.alojamiento.toUpperCase(), valor: total_alojamiento, css: {}},
					{icon: 'fastfood', label: this.lang.consumos_y_extras, valor: consumos_y_extras, hr: true},
					{icon: 'monetization_on', label: this.lang.total_mayuscula, valor: total_mayuscula , css: 'text-tomato'},
					{icon: 'credit_card', label:  this.lang.pagado, valor: pagado, css: 'text-green', hr: true},
					{icon: '', label: '', valor: pendiente, css: 'charge-total-pending'},
				]
			},
			currency(){ return this.$store.state.booking.moneda}
		},
		mounted() {

		},
		data() {
			return {
				booking           : {}
			}
		}
	}

</script>
